<template>
  <div sso-post>
    <div :class="{'success' : isSuccess}">
      <FontIcon name="success" :name="icon"/>
      <p v-html="desc" class="desc"/>
    </div>
    <a class="btn-ghost" />
  </div>
</template>

<script>
import { sleep } from '@shared/utils/commonUtils';
import { trackSegment } from '@/plugins/tracking';
import { ssoLogin } from '@/utils/clientUtil';
import FontIcon from '@shared/components/common/FontIcon.vue';
import base32 from '@/utils/base32';

export default {
  name: 'SsoPost',
  components: { FontIcon },
  data() {
    return {
      link: null,
      isSuccess: false,
    };
  },
  computed: {
    brandId() {
      return this.$route.query.brandId || '';
    },
    unhandled() {
      return !this.brandId;
    },
    icon() {
      return this.isSuccess ? 'check-circle' : 'close-circle';
    },
    desc() {
      return this.isSuccess ? this.descSuccess : this.descFail;
    },
    descFail() {
      return 'Unexpected error.<br/>' + 'Please return to the game and try reauthenticating.';
    },
    descSuccess() {
      return 'Your account has been successfully authenticated.<br/>' + 'Please return to the game.';
    },
    isPsk() {
      return this.brandId.toLowerCase() === 'psk' && this.$route.query.redirect;
    },
    isClubGg() {
      return this.brandId.toLowerCase() === 'clubgg' && this.$route.query.redirect;
    },
    haveToPostMsg() {
      return !this.isPsk && !this.isClubGg;
    },
    schemeUrl() {
      let url;
      if (this.isPsk) {
        url = `${process.env.VUE_APP_SSO_PSK_RETURN_URL}${location.search}`;
      } else if (this.isClubGg) { // clubgg 계정 삭제 페이지 연동
        url = `${process.env.VUE_APP_SSO_CLUBGG_RETURN_URL}${location.search}`;
      } else {
        const scheme = `${this.$route.query.schemeUrl}://ggpass-sso`;
        url = `${scheme}${location.search}`;
      }

      return url;
    }
  },
  methods: {
    async run() {
      // this.trackSsoSegment('sso login complete', { oauthUserId: this.$route.query.oauthUserId }); [ean] feat : 마테크 요청으로 SSO 처리 진행 시 segment 전달 부분 제거
      const a = this.$el.querySelector('.btn-ghost');
      a.addEventListener('click', (e) => {
        e.preventDefault();
        location.href = this.schemeUrl;
      });

      a?.click();

      if(this.haveToPostMsg) {
        await sleep(300);
        await ssoLogin(this.$route.query);
      }
      // await sleep(1000);
      // this.replaceRouteName('SsoPost', null, null);
    },
    trackSsoSegment(track, identify = null) {
      if(this.unhandled) return;
      try {
        if (this.$route.query.atag && identify) identify.a_tag = base32.decode(this.$route.query.atag);
      } catch (e) {
        console.log('error : trackSsoSegment aTag', this.$route.query.atag);
      }
      trackSegment(track, identify);
    },
  },
  mounted() {
    this.isSuccess = !this.unhandled && !this.$route.query.error;
    this.$nextTick(this.run);
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[sso-post] { .rel(); .h(var(--innerHeight));
  > div { .abs(); .lt(50%, 50%); .t-xyc();
    [font-icon] { .fs(60); .block(); .c(@c-red); }
    [color-button] { .w(240); .fs(20); .medium(); .h(48); }
    .desc { .m(36, 0, 100); .fs(20, 32); }
    &.success {
      [font-icon] { .c(@c-success); }
    }
  }
  .btn-ghost { .abs(); .wh(0, 0); .lt(-100, -100); }
}
</style>

